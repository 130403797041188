.tajneed-main h3 {
  font-weight: bold;
}
.tajneed-section {
  padding-bottom: 16px;
}
.tag__men-only {
  color: #020af6c4;
  text-decoration: underline;
  text-decoration-color: #020af6c4;
}
.tag__women-only {
  color: #ff54a9c4;
  text-decoration: underline;
  text-decoration-color: #ff54a9c4;
}
ul {
  list-style-type: none;
}

.list-none {
  list-style: none; /* Removes bullets */
  padding: 0;
  margin: 0;
}

.flex {
  display: flex;
  flex-wrap: wrap; /* Ensures responsive wrapping */
}

.w-1_2 {
  width: 50%; /* Two equal columns */
}

.pr-4 {
  padding-right: 20px; /* Adds space between columns */
}

.p-0 {
  padding: 0; /* No padding */
}

.m-0 {
  margin: 0; /* No margin */
}
