.resource_card_col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.resource_card {
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.resource_card_body {
  padding: 18px;
}
.resource_card_title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 18px;
}

.resource_card_action {
  padding: 8px;
  width: 100%;
  text-align: center;
  border-top: 1px solid #f0f0f0;
}
