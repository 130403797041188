.welcomeModalWrapper {
  text-align: center;
}
.action_button {
  padding-top: 12px;
  display: flex;
  justify-content: center;
}

.img {
  width: 240px;
  border-radius: 2px;
  margin-bottom: 12px;
  margin-top: 24px;
}

.title {
  font-size: 1.5rem;
}
.subtitle {
  font-size: 1.4rem;
}
.body {
  margin-top: 12px;
  min-height: 60px;
  font-size: 1rem;
}

@media (max-width: 420px) {
  .img {
    width: 140px;
    margin-top: 18px;
  }
  .title {
    font-size: 1.05rem;
  }
  .subtitle {
    font-size: 1rem;
    font-weight: bold;
  }
  .body {
    font-size: 0.75rem;
  }
  .button {
    font-size: 14px !important;
  }
}
