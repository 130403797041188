.arabic {
  text-align: end;
}

.resource_main {
  padding: 0px 25px 25px;
}
.resource_main h1 {
  font-size: 1.5rem;
}
.resource_main h1,
.resource_main h3 {
  text-align: center;
}

.resource_main .ant-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.13);
}

.resource_back {
  padding-bottom: 12px;
}

.resource_video {
  height: 385px;
  text-align: center;
}

.resource_video iframe {
  width: 700px;
  height: 385px;
}
@media (max-width: 1024px) {
  .resource_video iframe {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 756px) {
  .resource_video iframe {
    width: 100%;
    height: initial;
  }
}
