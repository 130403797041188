/* General styling */
h1 {
  font-size: 30px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

body .ant-btn {
  font-size: 16px;
}

body input.ant-input,
body select.ant-input,
body .ant-input-number,
body .ant-input-number-input {
  height: 40px;
}
body .ant-select .ant-select-selection-placeholder > div {
  height: 40px;
  line-height: 40px;
}

body div.ant-select,
body div.ant-select-selector,
body div.ant-select input {
  height: 40px !important;
}

body .ant-select-selection-item {
  line-height: 40px !important;
}

body .ant-select-auto-complete .ant-input.ant-select-search__field,
body .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 40px;
}

body .ant-select-selection,
body .ant-select-selection--single .ant-select-selection__rendered {
  height: 40px;
}

body .ant-select-selection-selected-value {
  margin-top: 3px;
}

.input-number-no-stepper .ant-input-number-handler-wrap {
  visibility: hidden;
}

@media (max-width: 420px) {
  #google-recaptcha-wrapper {
    transform: scale(0.8);
    /* transform-origin:0 0; */
  }
}
@media (max-width: 320px) {
  #google-recaptcha-wrapper {
    transform: scale(0.7);
    /* transform-origin:0 0; */
  }
}
/*  */

/* Main.js */
#sidebar-closeDrawer-onPhone {
  position: relative;
  height: 0px;
  top: 10px;
  right: 15px;
  color: #676767;
  text-align: right;
}

#sidebar-closeDrawer-onPhone svg {
  vertical-align: middle;
}
/*  */

/* ChandasForm.js */
.chandas-form .ant-form-item-label > label.ant-form-item-no-colon::after {
  margin: 0px;
}
/*  */

/* IdentificationForm.js */
.select__state {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
/*  */
.only_print {
  display: none;
}
@media print {
  body * {
    visibility: hidden;
  }
  .only_print {
    display: initial;
    text-align: center;
  }
  #printable,
  #printable * {
    visibility: visible;
  }
  #printable {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.ant-table
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
.ant-table .ant-form-item-explain.ant-form-item-explain-connected {
  display: none;
}
.ant-table .ant-row.ant-form-item {
  margin-bottom: 0px;
}
